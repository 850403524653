body{
	background-color: #f8f8f8;
}

.Login-main-container{
	width: 100% !important;
	height: 100% !important; 
	align-content: center;
}

#sign-in-card {
	width: 457px;
    height: 211px;
    border-radius: 30px;
	margin:0 auto;
	background-color: white;
	position: relative;
}

#sign-in-title {
	text-align: left;
	letter-spacing: 0px;
	color: #182027;
	text-transform: uppercase;
	opacity: 1;
    font-weight: 600;
}

.social-btn-height{
	height: 40px;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.social-buttons button {
  color: #0D131A;
  /*border: none;*/
  padding: 10px 8px; 
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px; 
  background-color: #fff; 
  transition: background-color 0.3s, transform 0.3s; /* Smooth background color change and scaling */
  box-sizing: border-box;
  justify-content: center;
  border:1px solid #747775;
}


.temp-border{
	border:2px solid red;
}

/* LOADER CSS */
#loader-wrapper {
    background: #fff none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.4s ease-out 0s;
    width: 100%;
    z-index: 1000;
    opacity: 0.9;
}
#loader {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    animation: 2s linear 0s normal none infinite running spin;
    border-color: transparent #FDDA0D #FDDA0D #FDDA0D;
    border-image: none;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    display: block;
    height: 60px;
    left: 50%;
    margin: -40px 0 0 -40px;
    position: fixed;
    top: 50%;
    width: 60px;
    z-index: 1001;

}
#loader::before {

    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    animation: 3s linear 0s normal none infinite running spin;
    border-color:  #FFEA00 #FFEA00 #FFEA00 transparent;

    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    bottom: 3px;
    content: "";
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
}
#loader::after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    animation: 1.5s linear 0s normal none infinite running spin;
    border-color: #FAFA33 #FAFA33 transparent #FAFA33;
    border-image: none;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    bottom: 10px;
    content: "";
    left: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}
@keyframes spin {
0% {
    transform: rotate(0deg);
}
50% {
    transform: rotate(180deg);
}

100% {
    transform: rotate(360deg);
}
}
#loader-wrapper .loader-section {
    background: #fff none repeat scroll 0 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 51%;
    z-index: 1000;
}
#loader-wrapper .loader-section.section-left {
    display: none;
    left: 0;
}
#loader-wrapper .loader-section.section-right {
    display: none;
    right: 0;
}
.loaded #loader-wrapper .loader-section.section-left {
    background: transparent none repeat scroll 0 0;
    transition: all 0.9s ease-out 0s;
}
.loaded #loader-wrapper .loader-section.section-right {
    background: transparent none repeat scroll 0 0;
    transition: all 0.9s ease-out 0s;
}
.loaded #loader {
    opacity: 0;
}
.loaded #loader-wrapper {
    background: transparent none repeat scroll 0 0;
    visibility: hidden;
}
/* LOADER CSS ENDS */