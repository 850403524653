* {
    font-size: 10pt;
}

:root{
    --bs-body-bg:rgb(240, 240, 240);
}

* {
  font-family: "Montserrat", sans-serif;
/*  scrollbar-width: thin;*/
}
body {
    height: 70vh;
    background-size: cover;
    background-color: rgb(240, 240, 240) !important;
}

#root {
	height: 100%;
}


.div-center {
	margin-top: 70px;
    height: 85vh;
        background: rgb(240, 240, 240);
/*    box-shadow: inset 1px 1px 11px 0px #0000003b;*/
}

.w100 {
	margin: 2%;
    height: 90vh;
    background: rgb(240, 240, 240);
}

.row{
/*    background: white;
    border-radius: 10px;*/
}

.white-bg {
	background-color: white;
    border-radius: 10px;
}

.agent_img{
  width: 30px;
  height: 30px;
}

.metrix-row {
    padding: 11px;
    background: rgb(240, 240, 240);
    border: 0px;
    box-shadow: none;
}

.FARow{
     background: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;

}

.FirstRow{
/*    padding: 11px;*/
    margin-top:10px;
    background: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
}
.SecondRow{
    background: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 15px;
    padding-right: 0px;

   
}
.ThirdRow{
    background: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 15px;
    padding-right: 0px;
    margin-top: -17px;
}
div.speaker-Customer.each_message_container {
/*  text-align: left;*/
  background: rgb(240, 240, 240);
  padding: 10px;
  border-radius: 3px;
  width: 80%;
  float: left;
  display: block;
  background: #fbfbfb73;
  border-radius: 5px;
  padding: 10px 5px;
/*  box-shadow: -2px 2px 4px 0px #9b9b9b4a;*/
  margin-top: 10px;
/*  font-size: 14pt;*/
}
div.speaker-Agent.each_message_container {
  /*text-align: right;*/
  background: rgb(240, 240, 240);
  padding: 10px;
  border-radius: 3px;
  width: 80%;
  float: right;
  display: block;
  background: #fbfbfb73;
  border-radius: 5px;
  padding: 10px 5px;
/*  box-shadow: -2px 2px 4px 0px #9b9b9b4a;*/
  margin-top: 10px;
/*  font-size: 14pt;*/
}
.each_message {
/*    font-size: 12pt;*/
}
span[class^="sentiment-tag"] {
      display: block;
      width: max-content;
      color: white;
      padding: 1px 4px;
      border-radius: 2px;
      margin-top: 9px;
    margin-bottom: -9px;
    }
    .sentiment-tag-Negative {
      background: #fe0000;
    }
    .sentiment-tag-Positive {
      background: #0dce1d;
    }
    .sentiment-tag-Neutral {
      background: #868686;
    }
.pitch-flag-true{
    margin-left: 2px;
    background: #0dce1d;
}
.pitch-flag-false{
    margin-left: 2px;
    background: #868686;
}
.response-dot {
     width: 8px;
     height: 8px;
     display: inline-block;
     border-radius: 50%;
     right: 0px;
     bottom: 0px;
     position: relative;
    }
.message-body{
    height: 347px;
    overflow: hidden; 
/*    max-height: 350px;*/
    background: white;
        border-radius: 0px 0px 10px 10px;
}

.message-body:hover  {
    overflow-y: auto;
}

.conversation-header{
    margin-left: 5px;
    height: inherit;
    display: flex;
    align-items: stretch;
    align-content: flex-start;
    border-radius: 10px;
}

.conversation-span{
    background: #fedf1a;
    width: 100%;
    padding-top: 3px;
    font-weight: 700;
    border-radius: 10px 10px 0px 0px;
    font-weight: normal;
    padding: 10px;
}

.customer{
    margin-left: 8px;
}
.topics-list{
        border-bottom: 1px solid #efefef;
    list-style-type: none;
    padding: 6px;
    border: none;
    border-bottom: 1px solid white;
}
.top-lists{
    padding: 10px;
    background: linear-gradient(to bottom, #bdffbd, #ecffec);
}
.pill-class{
    background-color: #e3e3e373;
    border: none;
    color: black;
    padding: 5px  12px 5px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 23px;
    flex-wrap: nowrap;;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
/*    cursor: pointer;*/
    border-radius: 2px;
}

.custom-gauge-chart text {
  font-size: 10px !important; /* Adjust the font size as needed */
   transform: translateY(10px);
}

.custom-gauge-chart .gauge-chart-text {
  /* Adjust this value as needed */
}
/*svg{
    height: 50px;
}*/
.text-ajust{
    text-align: center;
    height: 107px;
}
.customer-info{
    padding: 10px;
    min-height: 328px;
    align-content: space-around;
}
.user{
    font-size: 26px;
    margin-left: 8px;
}
h6{
    font-weight: 500 !important;
    color: #6d6d6d;
}
.speaker_tag{
    font-weight: 700;
}

.Agent-heading{
    float: left;
    padding-top: 15px;
    
}
.circle-cx-common-Yes{
    color: white;
    background-color: green;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    line-height: 56px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}
.circle-cx-common-No{
    color: white;
    background-color: red;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    line-height: 56px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}
.circle-cx-common-NA{
    color: white;
    background-color: yellow;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    line-height: 56px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}
.common-flex-call-details{
        display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.response-dot {
     width: 8px;
     height: 8px;
     display: inline-block;
     border-radius: 50%;
     right: 0px;
     bottom: 0px;
     position: relative;
    }
.raw-button{
    border-radius: 100px;
    color: black;
    border: none;
    background: none;
}
.img-target{
    width: 21px;
}
.heading-callinfo{
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0px;
}
.audio-class{
    width: 240px;
    height: 36px;
}

.audio-class::-webkit-media-controls-panel{
    background-color: #f2f2f2;
}


.common-align{
    align-items: flex-start;
}

[data-title]:hover:after { 
  content:attr(data-title);
  background-color:#000000;
  border:solid 1px #ffffff;
  padding:5px;
  color:white;
  display:block;
  width: 300px;
  position: absolute; 
  z-index: 1000; 
        }

.call-info-common-title[data-title]:hover:after { 
  content:attr(data-title);
  background-color:#000000;
  border:solid 1px #ffffff;
  padding:5px;
  color:white;
  display:block;
  width: auto;
  position: absolute; 
  z-index: 1000; 
}


.canvasjs-chart-tooltip div {
  max-width: 200px !important;
  white-space: normal !important;
}

.horizontal-division{
    margin-left: 37px;
}
.agentClass{
    padding: 10px;
    border-radius: 3px;

    float: right;
    display: block;
    background: #fbfbfb73;
    border-radius: 5px;
    padding: 10px 7px;
    box-shadow: -2px 2px 4px 0px #9b9b9b4a;
}

.customerClass{
    padding: 10px;
    border-radius: 3px;
    width: auto;
    float: left;
    display: block;
    background: #e3e3e373;
    border-radius: 5px;
    padding: 10px 7px;
    box-shadow: -2px 2px 4px 0px #9b9b9b4a;
}
.contentsAlign{
    align-items: center;
}


.audio-element{
    margin-left: -9px;
    width: 214px;
}
.call-info-body{
    margin-bottom: -12px;
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
}
.wrong-mark {
/*    display: inline-block;*/
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: relative;
    color: white;
    padding: 1px;
    display: flex;
    justify-content: center;
}

.right-mark {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 153, 0);
    border-radius: 50%;
    position: relative;
    color: white;
    font-weight: 700;
    padding: 1px;
}

.extended-css{
        display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.parent-class-customer-matrix {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


.tick-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: relative;
}
    .tick-circle::before {
    content: '\2713'; /* Unicode for check mark */
    color: white;
    font-size: 18px;
    position: absolute;
    top: 50%;
    font-weight: 700;
    left: 50%;
    transform: translate(-50%, -50%);
    }
.tick-circle-wrong {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 153, 0);
    border-radius: 50%;
    position: relative;
}
.tick-circle-wrong::before {
content: '\2716'; /* Unicode for check mark */
color: white;
font-size: 15px;
position: absolute;
top: 50%;
font-weight: 500;
left: 50%;
transform: translate(-50%, -50%);
}

.tick-circle-2 {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 153, 0);
    border-radius: 50%;
    position: relative;
}
.tick-circle-2::before {
content: '\2713'; /* Unicode for check mark */
color: white;
font-size: 18px;
position: absolute;
top: 50%;
font-weight: 700;
left: 50%;
transform: translate(-50%, -50%);
}
.tick-circle-2-wrong {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: relative;
}
.tick-circle-2-wrong::before {
content: '\2716'; /* Unicode for check mark */
color: white;
font-size: 15px;
position: absolute;
top: 50%;
font-weight: 500;
left: 50%;
transform: translate(-50%, -50%);
}
.customerMatrix{
    display: flex;
/*    align-items: flex-start;*/
}

.agentMatrix{
    display: flex;
    align-items: flex-start;
}

.CircularProgressbar  {
    height: 50px;
}

.custom-gauge-chart svg{
/*    height: 50px;*/
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
  position: fixed;
  top: 306px;
  left: 150px;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}
.loader-parent{
    position: absolute;
    left: 50%;
    top: 50%;
}
/*.common-matrix{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 57%;
}*/

.loader-matrix {
  width: 50px !important;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
  position: fixed;
    top: 320px;
    left: 917px;
}
.loader-matrix::before,
.loader-matrix::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader-matrix::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader-matrix::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}
.loader-matrix-parent{
    position: absolute;
    left: 50%;
    top: 50%;
}
.common-matrix {
        display: flex;
    flex-direction: column;
/*    height: 84px;*/
    justify-content: center;
}
/*
.canvasjs-chart-canvas {
    width: 452px !important;
    height: 332px !important;
}*/


.common-class {
    display: flex;
    align-content: center;
    height: fit-content;
    margin: 0px;
    width: 100%;
}

.button-matrix {
    background: none;
    border: none
}

.matrix-list {
   display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 10px;
}

.matrix-list-2 {
   display: flex;
    flex-direction: row;
    /* color: #fff; */
    border-radius: 1rem;
    margin-top: 6px;
    /* justify-content: flex-start; */
    /* border: 1px solid black; */
    align-items: center;
    padding: 10px 10px;
    box-shadow: 1px 1px 3px #0000001f;
    cursor: pointer;
}

.matrix-list-2:focus {

    background: rgb(240, 240, 240);
}

.matrix-list-2:active {

    background: rgb(240, 240, 240);
}

.button-matrix:focus {

    background: rgb(240, 240, 240);
}

.button-matrix:active {

   background: rgb(240, 240, 240);
}

.active{
    background: #edededc9;
}

footer{
    position: fixed;
    bottom: 0;
    margin-top: auto;
    width: 100%;
    background-color: rgb(51, 51, 51);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 36px;
}

.circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  color: black;
text-align: center;
border: 1px solid orange;
}



.nav-header{
    margin: 0px;
        text-decoration: none;
    font-weight: bold;
    color: black;
    font-size: 20px;
}

.navbar-brand{
    margin-left: 0;
    background-image: url(https://www.briotech.com/wp-content/uploads/2022/05/brio-black-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    min-width: 100px;
    max-width: fit-content;
    height: 40px;
    width: 100px;
}

.header-class {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


table {
  border-collapse: separate !important;
  width: 100%;
}

td {
  padding: 10px 0;
}


.custom-slider {
  margin-top: 18px;

  .rs-slider-handle {
    top: -6px;
    background-color: #3498ff;
    text-align: center;
    padding: 3px;
    margin-left: -16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 8px rgb(52 152 255 / 25%);
    }

    &::before {
      display: none;
    }
  }

  &.rs-slider-dragging .rs-slider-handle,
  &.rs-slider-dragging .rs-slider-handle:hover {
    box-shadow: none;
    transform: scale(1.2);
  }
}


.top-perfomers{
  padding: 10px;
  height: 200px;
  margin: 0px 0px 0px 10px;

}


.graph-class{
  padding: 20px;
  margin: 10px;
  height: 189px;
  display: flex;
  padding-top:39px;
  margin-right: 0px;


}

.topics-top{
  padding: 10px;
  margin: 0px 0px 0px 10px;
  background-color: white;
  height: 200px;
  margin-right: 0px;
}


.agent-score{
    display: flex;
    flex-direction: column;
    width: 143px;
    margin-left: 18px;
    margin-bottom: 0px;
    align-items: center;
}

.conversations{
    display: flex;
    flex-direction: column;
    width: 145px;
    margin-bottom: 0px;
    margin-left: 17px   ;
}

.agent-count{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conversations-count{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customers-interest{
    display: flex;
    flex-direction: column;
    width: 157px;
    margin-bottom: 0px;
    margin-left: 20px;
}

.customers-interest-count{
     display: flex;
    flex-direction: column;
    align-items: center;
}
#table-id tr:nth-child(even){background-color: #f2f2f2;}

.title-header{
    font-weight: 500 !important;
    color: #aaa5a5;
    font-size: 14px;
    margin-bottom: 0px;
}

.genai-count{

    display: flex;
    flex-direction: column;
    width: 134px;
    margin-bottom: 0px;
    margin-left: 20px;
}

.briologotxt{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-style {
    box-shadow: 1px 1px 5px #c3c3c3;
    background: #fedf1a;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    min-height: 50px;
    flex-wrap: nowrap !important;
}

/* ProgressBar.css */
.progress-container {
      display: flex;
      align-items: center;
      gap: 8px; /* Spacing between elements */
    }

.progress-bar {
        flex-grow: 1; /* Allow progress bar to fill available space */
        height: 10px;
        border-radius: 5px;
    }

.progress-min,
.progress-max {
      font-size: 16px;
      color: black;
}


.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

select:focus {
  border: none; /* Remove the border when focused */
  outline: none;
}

.dataFilters{
    font-size: 16px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background:'none'
}

.title-header-main{
    font-weight: 500;
}

  /* Styling for the progress bar background */
  .progress-bar::-webkit-progress-bar {
    background-color: lightgrey; /* Change the unfilled space to blue */
  }

  /* Optionally style the filled portion */
  .progress-bar::-webkit-progress-value {
    background-color: green; /* Change the filled space to green */
  }

  /* Styling for Firefox */
  .progress-bar {
    appearance: none; /* Ensure custom styles are applied */
  }
  .progress-bar::-moz-progress-bar {
    background-color: limegreen; /* Change the filled space to green */
  }


.gemini-icon{
    height: 16px;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
}

.gemini-icon-call{
     height: 16px;
    margin-top: 10px;
    margin-left: 8px;
    cursor: pointer;

}

.popup-content {
    width: 23% !important;
}

/* HTML: <div class="loader"></div> */
.loader-dots {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}


/* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
div::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}


.recent-conversations-class {
    overflow-x: scroll;
}
div:hover::-webkit-scrollbar {
  display: block;
  width: 3px; /* Set scrollbar width */
}

/* Style the scrollbar when visible */
div:hover::-webkit-scrollbar-thumb {
  background: #aaa; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

div:hover::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

.f-summary{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: inherit;
    overflow-y: scroll;
}

/* Maximized styles for chat window */
.chat-window.maximized {
  top: 50%;
  left: 50%;
  width: 60%;
  height: 70%;
  transform: translate(-50%, -50%);
  z-index: 1002;
}


.button-gemini {
  background: #03A9F4;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.no-data{
    display: flex;
    align-items: center;
    flex-direction: column;
}


.navbar-nav {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.navbar-nav .nav-item {
  margin-right: 20px; /* Adjust the spacing between items */
}


.headers{
    display: flex;
    justify-content: space-between;
}

.score_header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-class{
    color: #aaa5a5;
    font-size: 14px;
    font-weight: 500 !important;
    margin-bottom: 0;
    padding: 11px;
}

.value-class{
    color: green;
    font-size: 48px;
}

.agent-trend{
    padding: 6px;
/*    margin: 10px;*/
}

.top-requirements-class{
    background: white;
    height: 100%;
    overflow-x: scroll;
}

/*.recent-conversations-class{
    margin:20px;
}*/

.scores-all{
    padding: 10px;
    background: white;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.row-first{
    display: flex;
    align-items: center;

}

div{
    border-radius: 10px;
}

.secound-block {
    display: flex;
    flex-direction: column;
/*    gap:10px;*/
    padding-bottom: 10px;
}

.diff-class{
    font-size: 14px;
}

.arrow-class{
    font-size: 14px;
    margin-bottom: 5px
}

.agent-col{
    padding: 10px;
}

.header-section{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agent-matrix{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.InboundButton {
    background: green;
    color: white;
    padding: 15px;
    border-radius: 8px;
    border: none;
    margin-left: 2px;
    box-shadow: 0px 0px 10px 0px grey;
    line-height:6px;
    height:0px
}

.OutboundButton {
    background-color: gray;
    color: white;
    padding: 15px;
    border-radius: 8px;
    border: none;
    margin-left: 2px;
    box-shadow: 0px 0px 10px 0px grey;
    line-height:6px;
    height:0px
}

.scroll-container{
    height: 540px;
    overflow-y: scroll;
}

.actiontook {
    background: lightblue;
    padding: 10px
}
.actionrequired {
    background: yellow;
    padding: 10px
  }