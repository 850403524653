.iconDiv {
    position:fixed;
    bottom: 10px;
    right: 15px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
    z-index: 10000;
    }


.chatIconi {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 225, 19);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
    border-radius: 50%;
    
    }

.chatIcon{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    }

.chatIconi:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: transform 100ms ease-in-out;
    }

.overAllContainer {
    display: none;
    box-sizing: border-box;
    position:absolute ;
    right: 0px;
    bottom: 0px;
    color: rgb(51, 71, 91);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    height: 450px;
    line-height: 24px;
    unicode-bidi: isolate;
    width: 325px;
    cursor: pointer;
    z-index: 10000;

    }
.mainContainer{
    align-items: flex-end;
    box-sizing: border-box;
    color: rgb(51, 71, 91);
    column-gap: 12px;
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: flex-end;
    width: inherit;
    -webkit-box-pack: end;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
    }

.headerContext{
    height: 7%;
    width: 100%;
    background-color: rgb(255, 225, 19);
    display: flex;
    flex-direction: row;
    align-items: center;
    }

.logoTest img{
    height: 18%;
    width: 18%;
    object-fit: contain;
    max-width: 70%;
    max-height: 70%;
    margin: 0% 4%;
    }

.logoTest h5{
    width: 73%;
    margin-bottom:0px;
    font-size: 14px;
    font-weight: normal;

    }


.midContext{
    height: 85%;
    width: 100%;
    }

.input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    }

.footerContext {
    height: 8%;
    width: inherit;
    border-top: 2px solid rgb(242, 245, 248);
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;     /* Centers vertically */
    padding: 10px;
    background-color: white;
    }

.overAllContainer input[type="text"] {
    width: 80%;
    flex: 1;
    padding: 2px;
    background-color: #f1f1f1;
    opacity: 0.5;
    border: none;
    outline: none; /* Remove outline */
    margin-right: 10px; /* Adds space between input and button */
    }

.input-container button:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }

.overAllContainer input[type="text"]:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Apply box shadow on focus */
    outline: none; /* Remove outline */
    }

.overAllContainer .input-container button {
    padding: 5px 10px;
    color: black;
    border: none;
    cursor: pointer;
    background-color:white;
    font-size: 16px;
    width: 10%;
    }
.overAllContainer .input-container label{
    width: 10%;
    }

.midContext {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin
    }

.message-user {
    max-width: 80%;
    margin: 6px 0;
    padding: 6px;
    display: inline-block;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    background-color: white;
    border-radius: 5px;
    box-shadow: -2px 2px 6px 0px #9b9b9b54;
    }

.message-bot {
        padding: 10px;
    border-radius: 3px;
    width: auto;
    float: left;
    display: block;
    background: #e3e3e373;
    border-radius: 5px;
    padding: 10px 7px;
    box-shadow: -2px 2px 4px 0px #9b9b9b4a;
    }
.bot-message{
    margin-top: 10px;
    align-self: flex-start;
    text-align: left;
    justify-content: flex-start;
    align-items: baseline;
    display: flex;
    }

.user-message {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    text-align: left;
    }

.bot-icon {
    margin-right: 2px;
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    }

.attach-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    position: relative;
    font-size: 16px;
    color: black;
    }

.attach-button input[type="file"] {
    display: none;
    width: 15%;
    }
.sendB{
    width: 15%;
    }

.overAllContainer input[type="text"]:hover,
.attach-button:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }
.logoTest{
    display: flex;
    width: 54%;
    align-items: center;
    }
.reloadClose{
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    }
.emptydiv{
    width: 30%;
    }
.reloadClose button{
    border: none;
    background-color: inherit;
    padding: 0% 2%;
    font-size: 16px;
    }

.reloadClose button:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }



.audio-element{

    margin-left: -6px;
    width: 230px;
}

