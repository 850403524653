.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  font-family: "Montserrat", sans-serif;
}

/**murali's css/**
 
/* Ensure the body takes full height */
html, body {
  height: 100%; /* Take full viewport height */
  margin: 0;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.container-fluid {
  display: flex;
  flex-direction: column; /* Stack navbar, content, and footer vertically */
  min-height: 100%; /* Ensure the container takes at least the full height of the viewport */
  padding: 0px !important;
}

/* Navbar (fixed at the top) */
.navbar {
  width: 100%;
  height: 40px; /* Adjust as per your navbar height */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure navbar stays above other content */
}

/* Main content area */
.main-content {
  flex-grow: 1; /* This makes sure the content takes up the available space */
  margin-top: 51px; /* Margin to avoid overlapping the fixed navbar */
  padding: 10px; /* Ensure the content has margin from edges */
  overflow-y: auto; /* Allows scrolling if the content exceeds available space */
  margin-bottom: 6px; /* Leave space for the footer */
}

/* Footer styles */
footer {
  background-color: rgb(51, 51, 51); /* Example background */
  color: white;
  height: 36px; /* Adjust as per your footer height */
  width: 100%;
  text-align: center;
  margin-top: auto; /* Pushes the footer to the bottom if the content is small */
}
/*
::-webkit-scrollbar {
  width: 10px;
}*/

